import { Box, Button, Divider, Flex, IconButton, Link, Spacer, Spinner, Tag, Text } from "@chakra-ui/react";
import { MdComment, MdCopyAll, MdPhone, MdPrint, MdDeliveryDining } from "react-icons/md";
import { formatPrice } from 'utils/formatPrice';

export const OrderDetailView = ({loading, data, loadingPrint, onClickPrint}) => {
  if (loading) {
    return <Spinner />;
  }
  const {
    invoiceNo,
    numberOfItems,

    total,
    subtotal,
    orderDiscount,
    deliveryFee,
    deliveryTrackingUrl,
    codes,

    customerName,
    customerAddress,
    customerPhoneNumber,
    orderTime,
    preferredTime,
    items,
    note,
    deliveryMethodName
  } = data;

  const onClickDeliveryTracking = () => {
    window.open(deliveryTrackingUrl, "_blank");
  }

  return (
    <>
      <Flex flex={1}>
        <Box flex={1}>
          <Text fontWeight={"bold"}>Order Id</Text>
          <Text>{invoiceNo}</Text>
        </Box>
        <Box flex={1}>
          <Text fontWeight={"bold"}>Order Time</Text>
          <Text>{orderTime}</Text>
        </Box>
        <Box flex={1} textAlign={"right"}>
          <Text fontWeight={"bold"}>Preferred Time</Text>
          <Text>{preferredTime}</Text>
        </Box>
      </Flex>
      <Divider mt={2} mb={2} />

      <Flex flex={1} alignItems="center">
        <MdComment />
        <Text flex={1} ml={2}>{note}</Text>
        <Tag ml={2} borderRadius='full' variant='solid'>{deliveryMethodName}</Tag>
      </Flex> 
      <Divider mt={2} mb={2} />

      {subtotal !== total && (
          <Flex>
            <Text>{`Subtotal`}</Text>
            <Spacer />
            <Text>{subtotal}</Text>
          </Flex>
      )}
      {codes && (
        <Flex>
          <Text>{`Discount (${codes})`}</Text>
          <Spacer />
          <Text>{orderDiscount}</Text>
        </Flex>
      )}
      {(!!deliveryFee) && (
        <Flex>
          <Text>{`Delivery fee`}</Text>
          <Spacer />
          <Text>{formatPrice(deliveryFee)}</Text>
        </Flex>
      )}
      <Flex>
        <Text fontSize='xl' fontWeight={"bold"}>{`Total (${numberOfItems})`}</Text>
        <Spacer />
        <Text fontSize='xl' fontWeight={"bold"}>{total}</Text>
      </Flex>
      <Divider marginTop={2} marginBottom={2} />

      {items.map((item, index) => (
        <Flex key={index} alignItems="center" mb={2}>
          <Flex>
            <Text>X{item.quantity}</Text>
            <Box ml={2}>
              <Text>{item.name}</Text>
              {item.description.split("\n").map((line, index) => (
                <Text key={index}>{line}</Text>
              ))}
            </Box>
          </Flex>
        </Flex>
      ))}
      <Divider mt={2} mb={2} />

      <Flex alignItems={"center"}>
        <Text fontWeight={"bold"}>Customer:</Text>
        <Text ml={2}>{customerName}</Text>
      </Flex>
      <Box pl={2}>
        {/* <Flex alignItems={"center"} mt={2}>
          <MdLocationOn />
          <Text ml={2}>{customerAddress}</Text>
        </Flex> */}
        <Flex alignItems={"center"} mt={2}>
          <MdPhone />
          <Text ml={2}>{customerPhoneNumber || '--'}</Text>
          {!!customerPhoneNumber && <IconButton
            ml={2}
            icon={<MdCopyAll />}
            variant='outline'
            onClick={() => navigator.clipboard.writeText(customerPhoneNumber)}
          />}
        </Flex>
      </Box>
      <Divider mt={2} mb={2} />

      <Flex alignItems={"center"}>
        <Spacer />
        {!!deliveryTrackingUrl && (
          <Button
            size="sm"
            leftIcon={<MdDeliveryDining />}
            variant="outline"
            ml={2}
            onClick={onClickDeliveryTracking}
          >
            Tracking delivery
          </Button>
        )}
        <Button
          size="sm"
          leftIcon={<MdPrint />}
          variant="outline"
          ml={2}
          isLoading={loadingPrint}
          onClick={onClickPrint}
        >
          Print order
        </Button>
      </Flex>
    </>
  );
};
